<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authFackMethods } from "@/state/helpers";

/**
 * Elements component
 */
export default {
  page: {
    title: "User Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "User Profile",
      items: [
        {
          text: "User",
          href: "/user/list",
        },
        {
          text: "profile",
          active: true,
        },
      ],
      user:{
        fullname: '',
        contact1: '',
        contact2: '',
        email: '',
        remark: ''
      },
      password:{
        old: '',
        new_pass: '',
        new_pass2: '',
      }
    };
  },
  computed:{
    compute_user(){
      return this.$store.state.authfack.user
    },
    flag_enable(){
      if(this.password.old !== '' && this.password.new_pass !== '' && this.password.new_pass2 !== ''){
        return true
      }
      else{
        return false
      }
    }
  },
  created(){
    this.load_detail()
  },
  methods:{
    ...authFackMethods,
    load_detail(){
      let self = this
      
      const baseURI = 'users/'+self.compute_user.id
      self.$http.get(baseURI)
      .then((result) => {
        if(result.data.status == 'success'){
          self.user.fullname = result.data.data.name
         
          self.user.contact1 = result.data.data.primary_contact
          self.user.contact2 = result.data.data.secondary_contact
          self.user.email = result.data.data.email
          self.user.remark = result.data.data.remark
          
        }
      })
      .catch((err) => {
        if(err == 'session_timeout'){
          this.logout();
          this.$emit('stop_footer')
          this.$router.push({
            path: "/login",
          });
        }
      })
    },
    update_password(){
      let self = this
      if(self.password.new_pass !== self.password.new_pass2){
        alert('Password mismatch')
      }
      else{
        const baseURI = 'profiles/change_password'
        let formData = new FormData();          
        formData.append('id', self.compute_user.id )
        formData.append('new_password', self.password.new_pass )
        formData.append('old_password', self.password.old )

        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            // let a = JSON.parse(localStorage.getItem('user'))
            // console.log('result', result.data)
            // a.token = result.data.data.token
            localStorage.setItem('user', JSON.stringify(result.data))

            self.$notify({
                group: 'auth',
                title: 'Successfully update password',
                type: 'success'
              });
            self.password = {
              old: '',
              new_pass: '',
              new_pass2: ''
            }
          }
        })
      }
    },
    update(){
      let self = this
      const baseURI = 'users/'+self.compute_user.id
      let formData = new FormData();          
      formData.append('_method', 'PUT' )
      formData.append('name', self.user.fullname )
      formData.append('primary_contact', self.user.contact1 )
      formData.append('secondary_contact', self.user.contact2 )
      formData.append('email', self.user.email )
      formData.append('remark', self.user.remark )

      self.$http.post(baseURI,formData)
      .then((result) => {
        if(result.data.status == 'success'){
          self.$notify({
              group: 'auth',
              title: 'Update successful',
              type: 'success'
            });
          self.load_detail()
        }
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <b-form-group
              label="User Name / 用户姓名"
              label-for="form-user-name"
              class="mb-3"
            >
              <b-form-input
                id="form-user-name"
                type="text"
                v-model="user.fullname"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Contact No / 联系方式"
              label-for="form-user-contact1"
              class="mb-3"
            >
              <b-form-input
                id="form-user-contact1"
                type="text"
                v-model="user.contact1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Other Contact No / 其他联系方式"
              label-for="form-user-contact2"
              class="mb-3"
            >
              <b-form-input
                id="form-user-contact2"
                type="text"
                v-model="user.contact2"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <b-form-group
              label="Email / 电邮"
              label-for="form-user-email"
              class="mb-3"
            >
              <b-form-input
                id="form-user-email"
                type="text"
                v-model="user.email"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <b-form-group
          label="Remark / 备忘录"
          label-for="form-user-remark"
          class="mb-3"
        >
        <textarea id="form-user-remark" v-model="user.remark" class="form-control" rows="3" placeholder=""></textarea>
        </b-form-group>
        <button class="btn btn-info" @click="update">Update</button>
      </div>
    </div>

    <div class="card">
      <div class="card-title">
        Change Password
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <b-form-group
              label="Old Password / 旧密码"
              label-for="form-old-pass"
              class="mb-3"
            >
              <b-form-input
                id="form-old-pass"
                type="password"
                v-model="password.old"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <b-form-group
              label="New Password / 新密码"
              label-for="form-new-pass"
              class="mb-3"
            >
              <b-form-input
                id="form-new-pass"
                type="password"
                v-model="password.new_pass"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <b-form-group
              label="Reenter Password / 确认密码"
              label-for="form-new-pass2"
              class="mb-3"
            >
              <b-form-input
                id="form-new-pass2"
                type="password"
                v-model="password.new_pass2"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <button class="btn btn-info" @click="update_password" :disabled="!flag_enable">Update Password</button>
      </div>
    </div>
    
  </Layout>
</template>
